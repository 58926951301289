<template>
  <a v-if="$route.query.from" :href="$route.query.from" class="text-shadow" style=" font-size: 1.25em;">
    <i class="fas fa-arrow-left"></i>
  </a>
</template>
<script>
  export default {
    components: {
    },
    props: {
      pano: {
        default: {}
      },
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      }
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
    },
  }
</script>
<style scoped>
</style>
